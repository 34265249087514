import {Nav, Main} from './components'

function App() {
  return (
    <div className="App"> 
    <Nav />
    <Main />
    </div>
  );
}

export default App;
